<template>
  <div class="my-page-investor my-page" :class="{skeleton : !state.loaded }">
    <div class="part">
      <div class="subject">
        <span>{{ $store.state.account.memberName }}님은 <b class="color-point">{{ state.sortGrade }}</b>입니다.</span>
      </div>
      <table class="table font-sm date">
        <tbody>
        <tr>
          <th scope="row">
            <span>
              <span class="mr-2">투자자 유형 유효 기간</span>
              <a class="question btn btn-default" @click="$store.commit('openModal', { name: 'Info', params: { category: 'investorExpiryDate' }})">?</a>
            </span>
          </th>
          <td>
            <span v-if="['전문투자자', '적격투자자'].includes(state.sortGrade)">{{ state.currentInvestor.expiryStartDate }} ~ {{ state.currentInvestor.expiryEndDate }}</span>
            <span v-else-if="state.sortGrade === '일반투자자'">유효기간 없음</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="part">
      <div class="subject">
        <span>투자자 유형별 투자 한도 안내</span>
      </div>
      <div class="table-responsive-sm">
        <table class="table font-sm">
          <tbody>
          <tr>
            <th scope="row">
              <span>
                <span>일반투자자</span>
              </span>
            </th>
            <td>
              <dl>
                <dt>
                  <span>연간한도</span>
                </dt>
                <dd>
                  <span>1,000만원</span>
                </dd>
              </dl>
            </td>
            <td>
              <dl>
                <dt>
                  <span>동일발행인 한도</span>
                </dt>
                <dd>
                  <span>500만원</span>
                </dd>
              </dl>
            </td>
            <td>
              <dl>
                <dt></dt>
                <dd></dd>
              </dl>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <span>
                <span class="mr-2">적격투자자</span>
                <a class="question btn btn-default" @click="$store.commit('openModal', { name: 'Info', params: { category: 'investor1' }})">?</a>
              </span>
            </th>
            <td>
              <dl>
                <dt>
                  <span>연간한도</span>
                </dt>
                <dd>
                  <span>2,000만원</span>
                </dd>
              </dl>
            </td>
            <td>
              <dl>
                <dt>
                  <span>동일발행인 한도</span>
                </dt>
                <dd>
                  <span>1,000만원</span>
                </dd>
              </dl>
            </td>
            <td>
              <dl>
                <template v-if="!state.eligibleInvestor || state.eligibleInvestor.statusCode === '승인거절'">
                  <dd>
                    <button class="btn btn-secondary font-xs" @click="certify('02')">인증 신청</button>
                  </dd>
                </template>
                <template v-else-if="state.eligibleInvestor.statusCode === '승인완료'">
                  <template v-if="state.eligibleInvestor && state.eligibleInvestor.expireFlag === '2'">
                    <dt>유효 기간 만료</dt>
                    <dd>
                      <button class="btn btn-secondary font-xs" @click="certify('02')">인증 신청</button>
                    </dd>
                  </template>
                  <template v-else-if="state.eligibleInvestor && state.eligibleInvestor.expiryStartDate">
                    <dt>유효 기간</dt>
                    <dd>
                      <span>{{ state.eligibleInvestor.expiryStartDate }}</span>
                      <span> ~ </span>
                      <span>{{ state.eligibleInvestor.expiryEndDate }}</span>
                    </dd>
                  </template>
                </template>
              </dl>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <span>
                <span class="mr-2">전문투자자</span>
                <a class="question btn btn-default" @click="$store.commit('openModal', { name: 'Info', params: { category: 'investor2' }})">?</a>
              </span>
            </th>
            <td>
              <dl>
                <dt>
                  <span>연간한도</span>
                </dt>
                <dd>
                  <span>제한없음</span>
                </dd>
              </dl>
            </td>
            <td>
              <dl>
                <dt>
                  <span>동일발행인 한도</span>
                </dt>
                <dd>
                  <span>제한없음</span>
                </dd>
              </dl>
            </td>
            <td>
              <dl>
                <template v-if="!state.professionalInvestor || state.professionalInvestor.statusCode === '승인거절'">
                  <dd>
                    <button class="btn btn-secondary font-xs" @click="certify('03')">인증 신청</button>
                  </dd>
                </template>
                <template v-else-if="state.professionalInvestor.statusCode === '승인완료'">
                  <template v-if="state.professionalInvestor && state.professionalInvestor.expireFlag === '2'">
                    <dt>유효 기간 만료</dt>
                    <dd>
                      <button class="btn btn-secondary font-xs" @click="certify('03')">인증 신청</button>
                    </dd>
                  </template>
                  <template v-else-if="state.professionalInvestor && state.professionalInvestor.expiryStartDate">
                    <dt>유효 기간</dt>
                    <dd>
                      <span>{{ state.professionalInvestor.expiryStartDate }}</span>
                      <span> ~ </span>
                      <span>{{ state.professionalInvestor.expiryEndDate }}</span>
                    </dd>
                  </template>
                </template>
              </dl>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <span>
                <span class="mr-2">연고자 및 자문계약전문가</span>
                <a class="question btn btn-default" @click="$store.commit('openModal', { name: 'Info', params: { category: 'investor3' }})">?</a>
              </span>
            </th>
            <td>
              <dl>
                <dt>
                  <span>연간한도</span>
                </dt>
                <dd>
                  <span>제한없음</span>
                </dd>
              </dl>
            </td>
            <td>
              <dl>
                <dt>
                  <span>동일발행인 한도</span>
                </dt>
                <dd>
                  <span>제한없음</span>
                </dd>
              </dl>
            </td>
            <td>
              <dl>
                <dd>
                  <button class="btn btn-secondary font-xs mr-2" @click="certify('04')">인증 신청</button>
                  <button class="btn btn-secondary font-xs" @click="$store.commit('openModal', { name: 'InvestRelatives' })">투자 정보</button>
                </dd>
              </dl>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ul class="guide bottom tight font-sm">
      <li>투자자 유형을 변경하고자 할 경우, 투자자 유형별로 해당 증빙 서류를 제출하여야 합니다.</li>
      <li>증빙 서류 확인 절차에 다소 시간이 소요될 수 있습니다.</li>
      <li>연고자 및 자문계약전문가의 경우 해당 발행기업에 한하여 투자자 유형이 적용됩니다.</li>
    </ul>
    <div class="part history" v-if="state.sortList.length > 0">
      <div class="subject">
        <span>투자자 유형 인증 내역</span>
      </div>
      <div class="table-responsive-sm">
        <table class="table font-sm">
          <thead>
          <tr>
            <th>유형</th>
            <th>최종 신청일</th>
            <th>상태</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(s, idx) in state.sortList" :key="idx">
            <td>
              <span>
                <span>{{ s.investorSortCodeName }}({{ s.investorSortDetailCode }})</span>
                <p v-if="s.investorSortCode === '04'">{{ s.projectName }}</p>
              </span>
            </td>
            <td>
              <span>{{ s.expiryStartDate }}</span>
            </td>
            <td>
              <template>
                <span v-if="s.statusCode === '승인완료'">인증완료</span>
                <template v-else-if="s.statusCode === '승인거절'">
                  <div>거절</div>
                  <a class="color-anchor pointer-u font-sm" @click="reason(s.reason)">인증 거절 사유</a>
                </template>
                <span v-else>{{ s.statusCode }}</span>
              </template>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import Address from "@/components/Address";
import store from "@/scripts/store";
import Phone from "@/components/Phone";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageMyPageInvestorStatus";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Phone, Address},
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(async () => {
      await store.dispatch("setDocumentTitle", "투자자 인증");

      if (!await store.getters.investProjectAvailable("myPage")) {
        return store.commit("openModal", {
          name: "InvestProjectPauseNotice",
          routerReplace: true,
          preventClose: true
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      sortGrade: "일반투자자",
      associateInvestor: {},
      currentInvestor: {
        expiryStartDate: "",
        expiryEndDate: "",
      },
      eligibleInvestor: {
        statusCode: "",
        expireFlag: "",
        expiryStartDate: "",
        expiryEndDate: "",
      },
      professionalInvestor: {
        statusCode: "",
        expireFlag: "",
        expiryStartDate: "",
        expiryEndDate: "",
      },
      history: {
        count: 0,
        list: []
      },
      sortList: []
    });

    const apply = () => {
      console.log("apply");
    };

    const certify = (code) => {
      store.commit("openModal", {
        name: "InvestorCert",
        params: {code},
        callback: `${component.name}.load`
      });
    };

    const reason = (reason) => {
      store.commit("openModal", {
        name: "RefusalReason",
        params: {
          reason: reason,
        }
      });
    };

    const load = () => {
      state.loaded = false;

      http.get(`/api/investors/${store.state.investor.investorSeq}/investorSort`).then(({data}) => {
        state.loaded = true;
        state.associateInvestor = data.body.associateInvestor;
        state.currentInvestor = data.body.currentInvestor;
        state.eligibleInvestor = data.body.eligibleInvestor;
        state.professionalInvestor = data.body.professionalInvestor;
        state.sortGrade = data.body.sortGrade;
        state.sortList = data.body.sortList;
      });
    };

    return {component, state, apply, certify, reason, load};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-investor {
  .part {
    margin-bottom: $px40;

    .subject {
      margin-bottom: $px13;
    }

    .desc {
      color: #767676;
    }

    .warn {
      color: #ff6767;
      margin-top: $px10;
    }

    .modal-btn {
      padding: $px5;
    }

    .question {
      width: $px24;
      height: $px24;
      line-height: $px22;
      border: $px1 solid #ddd;
      padding: 0;
      background: #fff;
      border-radius: 50%;
    }

    table {
      border-bottom: $px1 solid #eee;
      margin-bottom: $px25;

      th, td {
        padding: $px15;
        vertical-align: middle;
        word-break: keep-all;
      }

      th {
        background: $colorBackground;
        border-bottom-width: $px1;

        > span span {
          display: inline-block;
          vertical-align: middle;
        }
      }

      td {
        dl {
          margin-bottom: 0;

          dd {
            margin-bottom: 0;
          }
        }

        button {
          width: $px80;
          height: $formHeight;
          line-height: $px45;
          margin-top: $px5;
          padding: 0;
          border: 0;
        }
      }

      &.date th {
        width: $px250;
      }
    }

    &.history {
      margin-top: $px40;
    }
  }

  &.skeleton {
    .part {
      .subject {
        > span, b {
          @include skeleton;
        }
      }

      .subject > span {
        @include skeleton;
      }

      .form-group::v-deep {
        select, input, .btn {
          @include skeleton;
        }

        .address .reset-btn {
          visibility: hidden;
        }
      }

      table {
        th {
          > span > span, .question {
            @include skeleton;
          }
        }

        td {
          span, button {
            @include skeleton;
          }
        }
      }

      &.cert .registration .row:after {
        content: none;
      }
    }

    .guide {
      li {
        @include skeleton;
      }
    }
  }

  @media(max-width: 767px) {
    .part table.date th {
      width: auto;
    }
  }
}
</style>